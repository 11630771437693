import React from 'react'
import styled from 'styled-components'

export const SidebarLogo = () => (
    <Container>
        <SVG viewBox="0 0 1217 236" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M247 112.878C250.007 54.437 297.011 7.43297 355.452 4.42497V112.878H247ZM367.367 4.27497H475.82C472.813 62.715 425.809 109.72 367.368 112.727L367.367 4.27497ZM247 233.4C250.007 174.958 297.011 127.954 355.452 124.947V233.4H247ZM367.367 124.795H475.82C472.813 183.237 425.809 230.241 367.368 233.248L367.367 124.795ZM482.086 112.878C485.093 54.437 532.097 7.43297 590.538 4.42497L590.539 112.878H482.086ZM602.457 4.27497H710.909C707.902 62.715 660.898 109.72 602.457 112.727V4.27497ZM482.086 233.4C485.093 174.958 532.097 127.954 590.538 124.947L590.539 233.4H482.086ZM602.457 124.795H710.909C707.902 183.237 660.898 230.241 602.457 233.248V124.795ZM0 112.878C3.007 54.437 50.011 7.43297 108.452 4.42497V112.878H0ZM228.975 4.27197C225.968 62.714 178.964 109.718 120.523 112.725V4.27197H228.975ZM108.452 233.248C50.012 230.241 3.007 183.237 0 124.795H108.452V233.248ZM120.523 124.947C178.965 127.954 225.969 174.958 228.976 233.4H120.523V124.947Z"
                fill="#FFF6E6"
            />
            <path
                d="M724.391 208.254C724.391 192.265 726.949 178.621 732.066 167.321C737.182 155.809 746.35 143.124 759.567 129.266C772.572 115.409 781.526 103.577 786.43 93.7697C791.546 83.9628 794.105 73.7296 794.105 63.0699C794.105 53.4762 792.506 46.8673 789.308 43.243C786.323 39.4055 781.846 37.4868 775.877 37.4868C763.725 37.4868 757.649 44.8419 757.649 59.5522V83.5365H724.391V61.7908C724.391 43.6694 728.868 29.8118 737.822 20.2181C746.776 10.4113 759.781 5.50781 776.836 5.50781C793.892 5.50781 806.896 10.4113 815.85 20.2181C824.805 29.8118 829.282 43.6694 829.282 61.7908C829.282 75.4351 825.977 88.7597 819.368 101.764C812.972 114.769 801.78 129.693 785.79 146.535C775.344 157.621 768.308 166.682 764.684 173.717C761.06 180.539 759.248 187.894 759.248 195.783V200.579H826.084V232.558H724.391V208.254Z"
                fill="#FFF6E6"
            />
            <path
                d="M903.917 235.756C886.649 235.756 873.431 230.853 864.264 221.046C855.096 211.239 850.513 197.382 850.513 179.473V61.7908C850.513 43.8826 855.096 30.025 864.264 20.2181C873.431 10.4113 886.649 5.50781 903.917 5.50781C921.186 5.50781 934.404 10.4113 943.571 20.2181C952.739 30.025 957.322 43.8826 957.322 61.7908V179.473C957.322 197.382 952.739 211.239 943.571 221.046C934.404 230.853 921.186 235.756 903.917 235.756ZM903.917 203.777C916.069 203.777 922.145 196.422 922.145 181.712V59.5522C922.145 44.8419 916.069 37.4868 903.917 37.4868C891.765 37.4868 885.689 44.8419 885.689 59.5522V181.712C885.689 196.422 891.765 203.777 903.917 203.777Z"
                fill="#FFF6E6"
            />
            <path
                d="M980.472 208.254C980.472 192.265 983.03 178.621 988.147 167.321C993.264 155.809 1002.43 143.124 1015.65 129.266C1028.65 115.409 1037.61 103.577 1042.51 93.7697C1047.63 83.9628 1050.19 73.7296 1050.19 63.0699C1050.19 53.4762 1048.59 46.8673 1045.39 43.243C1042.4 39.4055 1037.93 37.4868 1031.96 37.4868C1019.81 37.4868 1013.73 44.8419 1013.73 59.5522V83.5365H980.472V61.7908C980.472 43.6694 984.949 29.8118 993.903 20.2181C1002.86 10.4113 1015.86 5.50781 1032.92 5.50781C1049.97 5.50781 1062.98 10.4113 1071.93 20.2181C1080.89 29.8118 1085.36 43.6694 1085.36 61.7908C1085.36 75.4351 1082.06 88.7597 1075.45 101.764C1069.05 114.769 1057.86 129.693 1041.87 146.535C1031.43 157.621 1024.39 166.682 1020.77 173.717C1017.14 180.539 1015.33 187.894 1015.33 195.783V200.579H1082.17V232.558H980.472V208.254Z"
                fill="#FFF6E6"
            />
            <path
                d="M1167.99 191.945H1100.84V159.966L1164.8 8.7057H1203.17V159.966H1219.8V191.945H1203.17V232.558H1167.99V191.945ZM1167.99 159.966V72.6636H1167.35L1132.82 159.966H1167.99Z"
                fill="#FFF6E6"
            />
        </SVG>
    </Container>
)

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 67px;
`

const SVG = styled.svg`
    width: 60%;
`

export default SidebarLogo
